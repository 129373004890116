import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Icon,
  Image,
  Card,
  Divider,
  Form,
  Button,
  Segment,
} from "semantic-ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { navigate } from "gatsby";
import _ from "lodash";

import { s3Upload } from "../../libs/awsLib";
import s3config from "../../s3config";

import shorthash from "shorthash";
import ImageSection from "../sections/components/ImageSection";
import ProcessingImages from "./processingImages";
import { processImage } from "../../graphql/queries";

import moment from "moment";
import {
  addImage,
  update_image,
  update_Section,
  removeImage,
  fetchJunctionPages,
  fetchPages,
  fetchPathways,
  fetchSections,
  fetchTiles,
  fetchWellnessForms,
  fetchWellnessQuestions,
  fetchImages,
} from "../../actions";
import ImageCard from "./imageCard";
import UploadVideoSection from "./UploadImage"; 

const Index = () => {
  const dispatch = useDispatch();
  const [uploadModalOpen, update_uploadModalOpen] = useState(false);
  const [collected_images, update_collected_images] = useState([]);
  const [timestamp, update_timestamp] = useState(moment().unix());
  const user = useSelector((state) => state.user);
  const sections = useSelector((state) => state.sections);
  const state = useSelector((state) => state);
  const [images, update_images] = useState([...state.images]);

  useEffect(() => {
    dispatch(fetchPathways(user["custom:trust"]));
    dispatch(fetchTiles(user["custom:trust"]));
    dispatch(fetchPages(user["custom:trust"]));
    dispatch(fetchSections(user["custom:trust"]));
    dispatch(fetchJunctionPages(user["custom:trust"]));
    dispatch(fetchWellnessForms(user["custom:trust"]));
    dispatch(fetchWellnessQuestions(user["custom:trust"]));
    dispatch(fetchImages());
  }, []);

  useEffect(() => {
    const collected_images_to_return = [];
    const filter_section_images = _.filter(sections, { type: "image" });
    sections.map(function (image) {
      try {
        if (image.image_url) {
          if (image.image_type !== "external") {
            collected_images_to_return.push(image);
          }
        }
      } catch (error) {
        console.log(error);
      } 
      update_collected_images(collected_images_to_return);
    });
  }, [sections]);

  async function upload(event) {
    console.log("running upload function");
    update_uploadModalOpen(true);
    const file = event.target.files[0];
    console.log(file);
    const newFile = await s3Upload(file);
    console.log(newFile);
    const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
    const hash = shorthash.unique(public_url);
    const data = {
      image_url: public_url,
      hash: hash,
    };
    dispatch(addImage(data));

    //   update_image_url(public_url);
    //   update_image_hash(hash);
    //   update_original_image_key(newFile);
    //   update_uploadModalOpen(false);
  }

  async function updateImageFromLibrary(img, sec) {
    console.log("img");
    console.log(img);
    console.log("for this section");
    console.log(sec);

    const section_data = {
      id: sec.id,
      image_type: "external",
      image_hash: img.hash,
      image_url: img.image_url,
    };
    dispatch(update_Section({ Item: section_data }));
  }
  async function addToImageLibrary(section) {
    const data = {
      image_url: section.image_url,
      original_image: section.image_url,
    };
    console.log(data);
    const response = await API.graphql(
      graphqlOperation(processImage, {
        image: JSON.stringify({
          params: data,
          size: 400,
          url: section.image_url,
        }),
      })
    );
    const hash = shorthash.unique(section.image_url);

    const parsed_response = JSON.parse(response.data.processImage);
    const parsed_response_body = JSON.parse(parsed_response.body);
    const new_url = JSON.parse(parsed_response_body);
    const params = {
      image_url: new_url,
      version_id: shorthash.unique(new_url),
      original_image: section.image_url,
      hash: hash,
    };

    dispatch(addImage(params));

    // we need to update the section now
    const section_data = {
      id: section.id,
      image_type: "external",
      image_hash: hash,
    };
    dispatch(update_Section({ Item: section_data }));
  }

  function saveImage(image) {
    const data = {
      id: image.id,
      title: image.title,
      hash: image.hash,
    };
    console.log(data);
    dispatch(update_image(data));
  }

  function makeImageExternal(section) {
    console.log("making image external");
    console.log(section);
    const data = {
      id: section.id,
      image_type: "external",
      //   image_hash: "Z17Pznf",
    };
    dispatch(update_Section({ Item: data }));
  }

  function returnImages() {
    return collected_images.map((image) => {
      return <div style={{ display: "inline" }}>{returnImageCard(image)}</div>;
    });
  }

  function returnImageCard(section) {
    const find_page = _.find(state.pages, { page_id: section.section_id });
    return (
      <div style={{ marginBottom: 20 }}>
        <ImageCard
          section={section}
          find_page={find_page}
          makeImageExternal={makeImageExternal}
          navigate={navigate}
          addToImageLibrary={addToImageLibrary}
          updateImageFromLibrary={updateImageFromLibrary}
        />
      </div>
    );
  }

  function handleUpdateImage(event, index) {
    var new_ob = [...state.images];
    console.log(state.images);
    console.log(new_ob[index]);

    switch (event.target.name) {
      case "title":
        new_ob[index].title = event.target.value;
        update_images(new_ob);
        break;
      case "hash":
        new_ob[index].hash = event.target.value;
        update_images(new_ob);
        break;

      default:
        break;
    }
    // var new_ob = [...images];
    // new_ob[index].data = event.target.value;
    // console.log(event.target.value);
    // update_images(new_ob);
  }

 
  return (
    <div>
      <h1>Videos</h1>
      <Segment>
        <h3>Upload Video</h3>
        <UploadVideoSection returnImage={null} />
      </Segment>
      {/* <Segment secondary>
        <h3>Edit Image</h3>
        <ProcessingImages />
      </Segment> */}
      <Divider />
    </div>
  );
};

export default Index;
